<template>
  <div id="App">
    <van-steps :active="0">
      <van-step>选择日期</van-step>
      <van-step>选择场地时间</van-step>
      <van-step>预约完成</van-step>
    </van-steps>
    <van-calendar
      :show-title="false"
      :show-subtitle="false"
      :poppable="false"
      :show-confirm="false"
      :min-date="minDate"
      :max-date="maxDate"
      :formatter="formatter"
      :default-date="confirmDate"
       @confirm="onConfirm"
    />
    <Tabbar></Tabbar>
    <van-overlay :show="!finished">
      <div class="wrapper">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<style>
.ym {
  color: blue;
}
.xg {
  color: crimson;
}
</style>
<script>
import { mapState } from "vuex";
import Tabbar from "../components/Tabbar.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      confirmDate: null,
      minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      maxDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      title: "新增预约",
      listDate: [],
      loading: false,
      finished: false
    };
  },
  components: {
    Tabbar
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    formatter(day) {
      this.listDate.filter(item => {
        if (this.isSameDay(new Date(item.DATE), day.date)) {
          if (item.TYPE == "2") {
            day.className = "xg";
            day.bottomInfo = "休馆";
            day.type = "disabled";
          }
        }
      });
      return day;
    },
    isSameDay(dateA, dateB) {
      return (dateA.setHours(0, 0, 0, 0) == dateB.setHours(0, 0, 0, 0));
    },
    onConfirm(date) {
      this.$router.push({
        path: "/selyysj",
        name: "SelYySj",
        query: {
          Date: date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() ,
        },
      });
      this.loading = true;
      this.confirmDate = date;
    },
  },
  created: function() {
    this.loading = true;
    var that = this;
    that
      .$axios({
        method: "post",
        url: "/api/Main",
        data: {
          Method: "API_getYYRQ"
        },
        headers: {
          Authorization: "Bearer " + that.userInfo.token
        }
      })
      .then(response => {
        if (response.data.status == "200") {
          that.minDate = new Date(response.data.Table1[0].MINDATE);
          that.maxDate = new Date(response.data.Table1[0].MAXDATE);
          that.listDate = response.data.Table;
          that.finished = true;
        } else {
          Dialog({ message: response.data.msg });
          that.finished = true;
        }
      })
      .catch(error => {
        //弹出失败提醒
        Dialog({ message: error.message });
        that.finished = true;
      });
  }
};
</script>